var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TopUpForm-waapper" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.ChargeConfig } },
        [
          _c("el-form-item", { attrs: { label: "充值方式", required: "" } }, [
            _c(
              "div",
              { staticClass: "disflex flexdc left_advertising" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "ml10",
                    on: { change: _vm.changeradio },
                    model: {
                      value: _vm.ChargeConfig.actChargeType,
                      callback: function($$v) {
                        _vm.$set(_vm.ChargeConfig, "actChargeType", $$v)
                      },
                      expression: "ChargeConfig.actChargeType"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mt10" },
                      [
                        _c("el-radio", { attrs: { label: "staff_auto" } }, [
                          _vm._v(
                            "客户自充 （导购员注册后需充值方可进入KPI页面）"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c("el-radio", { attrs: { label: "act" } }, [
                          _vm._v(
                            "活动方代充 （导购员注册后无需充值直接进入KPI页面）"
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm.ChargeConfig.actChargeType == "staff_auto"
            ? _c(
                "div",
                [
                  _c("el-form-item", [
                    _c("div", { staticClass: "form-item-box" }, [
                      _c(
                        "div",
                        { staticClass: "item-title disFlex Flex-center" },
                        [_vm._v("品牌费用:" + _vm._s(_vm.CustomerBrandTotal))]
                      ),
                      _c(
                        "div",
                        { staticClass: "disFlex Flex-wrap item-input-box" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 品牌奖励豆奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.brandBonusBean,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "brandBonusBean",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.brandBonusBean"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员奖励豆奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.staffBonusBean,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "staffBonusBean",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.staffBonusBean"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 品牌惩罚承诺金 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.brandPenalty,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "brandPenalty",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.brandPenalty"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 客户分享奖励 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.userShareBonus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "userShareBonus",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.userShareBonus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员单项奖励金 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.staffSingleBonus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "staffSingleBonus",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.staffSingleBonus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 直播间红包 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.liveRedPackage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "liveRedPackage",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.liveRedPackage"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 其他推广费用 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value:
                                    _vm.ChargeConfig.otherPromotionExpenses,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "otherPromotionExpenses",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "ChargeConfig.otherPromotionExpenses"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("el-form-item", [
                    _c("div", { staticClass: "form-item-box" }, [
                      _c(
                        "div",
                        { staticClass: "item-title disFlex Flex-center" },
                        [_vm._v("导购员费用:" + _vm._s(_vm.CustomerStaffTotal))]
                      ),
                      _c(
                        "div",
                        { staticClass: "disFlex Flex-wrap item-input-box" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.sanqiu,
                                  maxlength: "10",
                                  type: "number",
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ChargeConfig.staffBonus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ChargeConfig,
                                      "staffBonus",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ChargeConfig.staffBonus"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("el-form-item", [
                    _c("div", { staticClass: "form-item-box" }, [
                      _c(
                        "div",
                        { staticClass: "item-title disFlex Flex-center" },
                        [
                          _vm._v(
                            " 品牌费用:" + _vm._s(_vm.ActivityBrandTotal) + " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "option-btn",
                              on: {
                                click: function($event) {
                                  return _vm.handleEdit(1)
                                }
                              }
                            },
                            [_vm._v("充值")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "disFlex Flex-wrap item-input-box" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 品牌奖励豆奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.brandBonusBean,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "brandBonusBean",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.brandBonusBean"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员奖励豆奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.staffBonusBean,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "staffBonusBean",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.staffBonusBean"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 品牌惩罚承诺金 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.brandPenalty,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "brandPenalty",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.brandPenalty"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 客户分享奖励 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.userShareBonus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "userShareBonus",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.userShareBonus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员单项奖励金 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.staffSingleBonus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "staffSingleBonus",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.staffSingleBonus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 直播间红包 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.liveRedPackage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "liveRedPackage",
                                      $$v
                                    )
                                  },
                                  expression: "ActivityObj.liveRedPackage"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 其他推广费用 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.ActivityObj.otherPromotionExpenses,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ActivityObj,
                                      "otherPromotionExpenses",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ActivityObj.otherPromotionExpenses"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("el-form-item", [
                    _c("div", { staticClass: "form-item-box" }, [
                      _c(
                        "div",
                        { staticClass: "item-title disFlex Flex-center" },
                        [
                          _vm._v(" 导购员费用:" + _vm._s(_vm.StaffBonus) + " "),
                          _c(
                            "span",
                            {
                              staticClass: "option-btn",
                              on: {
                                click: function($event) {
                                  return _vm.handleEdit(0)
                                }
                              }
                            },
                            [_vm._v("充值")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "disFlex Flex-wrap item-input-box" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _vm._v(" 导购员奖金池 "),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入数值"
                                },
                                model: {
                                  value: _vm.StaffBonus,
                                  callback: function($$v) {
                                    _vm.StaffBonus = $$v
                                  },
                                  expression: "StaffBonus"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fvc f" },
        [
          _vm.ChargeConfig.actChargeType == "staff_auto"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitChargeConfig }
                },
                [_vm._v("保 存")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editDialog, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.editDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c("el-form-item", [
                _c("div", { staticClass: "form-item-box" }, [
                  _c("div", { staticClass: "item-title disFlex Flex-center" }, [
                    _vm._v("品牌费用:" + _vm._s(_vm.ActivityBrandTotal))
                  ]),
                  _c(
                    "div",
                    { staticClass: "disFlex Flex-wrap item-input-box" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 品牌奖励豆奖金池 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.brandBonusBean,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "brandBonusBean",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.brandBonusBean"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 导购员奖励豆奖金池 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.staffBonusBean,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "staffBonusBean",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.staffBonusBean"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 品牌惩罚承诺金 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.brandPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "brandPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.brandPenalty"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 客户分享奖励 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.userShareBonus,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "userShareBonus",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.userShareBonus"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 导购员单项奖励金 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.staffSingleBonus,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "staffSingleBonus",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.staffSingleBonus"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 直播间红包 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.liveRedPackage,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "liveRedPackage",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.liveRedPackage"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 其他推广费用 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.otherPromotionExpenses,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editActivity,
                                  "otherPromotionExpenses",
                                  _vm._n($$v)
                                )
                              },
                              expression: "editActivity.otherPromotionExpenses"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveeditActivity }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editStaff, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.editStaff = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c("el-form-item", [
                _c("div", { staticClass: "form-item-box" }, [
                  _c("div", { staticClass: "item-title disFlex Flex-center" }, [
                    _vm._v("导购员费用:" + _vm._s(_vm.StaffBonus))
                  ]),
                  _c(
                    "div",
                    { staticClass: "disFlex Flex-wrap item-input-box" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _vm._v(" 导购员奖金池 "),
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              type: "number",
                              placeholder: "请输入数值"
                            },
                            model: {
                              value: _vm.editActivity.amount,
                              callback: function($$v) {
                                _vm.$set(_vm.editActivity, "amount", $$v)
                              },
                              expression: "editActivity.amount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editStaff = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveeditStaff }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="TopUp-waapper">
    <el-tabs v-model="currView" type="card">
      <el-tab-pane label="充值费用设置" name="TopUpForm"></el-tab-pane>
      <el-tab-pane label="充值记录" name="TopUpDetail"></el-tab-pane>
      <!-- <el-tab-pane label="充值费用池" name="TopUpPool"></el-tab-pane> -->
    </el-tabs>

    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>

    <el-dialog :visible.sync="topUpDialog" width="80%" :show-close="false">
      <top-up-form></top-up-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="topUpDialog = false">取消</el-button>
        <el-button type="primary" @click="topUpDialog = false">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import FinanceAjax from "@/utils/https/modules/Finance.request.js";

import TopUpForm from "./TopUpChildren/TopUpForm";
export default {
  name: "TopUp", //充值
  components: {
    TopUpForm: () => import("./TopUpChildren/TopUpForm"),
    TopUpDetail: () => import("./TopUpChildren/TopUpDetail"),
    // TopUpPool: () => import("./TopUpChildren/TopUpPool")
  },
  props: {
    activityID: String
  },
  data() {
    return {
      options: [],
      value: "",
      currentPage4: 0,
      currView: "TopUpForm",
      form: {
        resource: 1
      },
      topUpDialog: false,
      tableData: [
        {
          id: 1,
          name: 1,
          brand: 1,
          shop: 1,
          topUpTime: 1,
          topUpAmount: 1
        }
      ],
      tableData1: [
        {
          id: 1,
          name: 1,
          phone: 1,
          brand: 1,
          shop: 1,
          brandAwardBeanBonusPool: 1,
          staffAwardBeanBonusPool: 1,
          brandPenaltyCommitment: 1,
          customerSharingReward: 1,
          staffIndividualReward: 1,
          liveRedpack: 1,
          otherPromotionFee: 1,
          topUpTotalAmount: 1,
          topUpTime: 1
        }
      ],
      tableData2: [
        {
          id: 1,
          name: 1,
          phone: 1,
          topUpType: 1,
          topUpAmount: 1,
          topUpTime: 1
        }
      ]
    };
  },
  methods: {
    handleCurrentChange(val) {}
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.TopUp-waapper {
  .form-item-box {
    background: rgba(153, 153, 153, 0.075);
    padding: 40px;
    color: black;
    .item-title {
      font-size: 22px;
      font-weight: 600;
    }
    .item-input-box {
      div {
        margin-right: 40px;
      }
    }
  }
  .option-btn {
    font-size: 13px;
    color: #0c7efe;
    display: inline-block;
    margin-left: 20px;
  }
}
</style>
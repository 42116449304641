var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TopUp-waapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "充值费用设置", name: "TopUpForm" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "充值记录", name: "TopUpDetail" }
          })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currView, {
            tag: "component",
            attrs: { activityID: _vm.activityID }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.topUpDialog,
            width: "80%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.topUpDialog = $event
            }
          }
        },
        [
          _c("top-up-form"),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.topUpDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.topUpDialog = false
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="TopUpForm-waapper">
    <el-form ref="form" :model="ChargeConfig">
      <el-form-item label="充值方式" required>
        <div class="disflex flexdc left_advertising">
          <el-radio-group v-model="ChargeConfig.actChargeType" class="ml10" @change="changeradio">
            <div class="mt10">
              <el-radio label="staff_auto">客户自充 （导购员注册后需充值方可进入KPI页面）</el-radio>
            </div>
            <div class="mt20">
              <el-radio label="act">活动方代充 （导购员注册后无需充值直接进入KPI页面）</el-radio>
            </div>
          </el-radio-group>
        </div>
      </el-form-item>
      <div v-if="ChargeConfig.actChargeType=='staff_auto'">
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">品牌费用:{{CustomerBrandTotal}}</div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                品牌奖励豆奖金池
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.brandBonusBean"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                导购员奖励豆奖金池
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.staffBonusBean"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                品牌惩罚承诺金
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.brandPenalty"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                客户分享奖励
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.userShareBonus"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                导购员单项奖励金
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.staffSingleBonus"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                直播间红包
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.liveRedPackage"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                其他推广费用
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.otherPromotionExpenses"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">导购员费用:{{CustomerStaffTotal}}</div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                导购员奖金池
                <el-input
                  :disabled="sanqiu"
                  maxlength="10"
                  type="number"
                  v-model.number="ChargeConfig.staffBonus"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">
              品牌费用:{{ActivityBrandTotal}}
              <span class="option-btn" @click="handleEdit(1)">充值</span>
            </div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                品牌奖励豆奖金池
                <el-input :disabled="true" v-model="ActivityObj.brandBonusBean" placeholder="请输入数值"></el-input>
              </el-col>
              <el-col :span="4">
                导购员奖励豆奖金池
                <el-input :disabled="true" v-model="ActivityObj.staffBonusBean" placeholder="请输入数值"></el-input>
              </el-col>
              <el-col :span="4">
                品牌惩罚承诺金
                <el-input :disabled="true" v-model="ActivityObj.brandPenalty" placeholder="请输入数值"></el-input>
              </el-col>
              <el-col :span="4">
                客户分享奖励
                <el-input :disabled="true" v-model="ActivityObj.userShareBonus" placeholder="请输入数值"></el-input>
              </el-col>
              <el-col :span="4">
                导购员单项奖励金
                <el-input
                  :disabled="true"
                  v-model="ActivityObj.staffSingleBonus"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                直播间红包
                <el-input :disabled="true" v-model="ActivityObj.liveRedPackage" placeholder="请输入数值"></el-input>
              </el-col>
              <el-col :span="4">
                其他推广费用
                <el-input
                  :disabled="true"
                  v-model="ActivityObj.otherPromotionExpenses"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">
              导购员费用:{{StaffBonus}}
              <span class="option-btn" @click="handleEdit(0)">充值</span>
            </div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                导购员奖金池
                <el-input :disabled="true" v-model="StaffBonus" placeholder="请输入数值"></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="fvc f">
      <el-button
        type="primary"
        @click="submitChargeConfig"
        v-if="ChargeConfig.actChargeType=='staff_auto'"
        style="width:200px"
      >保 存</el-button>
    </div>
    <el-dialog :visible.sync="editDialog" width="80%">
      <el-form>
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">品牌费用:{{ActivityBrandTotal}}</div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                品牌奖励豆奖金池
                <el-input
                  v-model.number="editActivity.brandBonusBean"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                导购员奖励豆奖金池
                <el-input
                  v-model.number="editActivity.staffBonusBean"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                品牌惩罚承诺金
                <el-input
                  v-model.number="editActivity.brandPenalty"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                客户分享奖励
                <el-input
                  v-model.number="editActivity.userShareBonus"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                导购员单项奖励金
                <el-input
                  v-model.number="editActivity.staffSingleBonus"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                直播间红包
                <el-input
                  v-model.number="editActivity.liveRedPackage"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
              <el-col :span="4">
                其他推广费用
                <el-input
                  v-model.number="editActivity.otherPromotionExpenses"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取消</el-button>
        <el-button type="primary" @click="saveeditActivity">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="editStaff" width="80%">
      <el-form>
        <el-form-item>
          <div class="form-item-box">
            <div class="item-title disFlex Flex-center">导购员费用:{{StaffBonus}}</div>
            <div class="disFlex Flex-wrap item-input-box">
              <el-col :span="4">
                导购员奖金池
                <el-input
                  v-model.float="editActivity.amount"
                  maxlength="10"
                  type="number"
                  placeholder="请输入数值"
                ></el-input>
              </el-col>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editStaff = false">取消</el-button>
        <el-button type="primary" @click="saveeditStaff">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import FinanceAjax from "@/utils/https/modules/Finance.request.js";
import { getSession } from "@/utils/utils.js";
export default {
  name: "TopUpForm", //充值费用设置
  props: {
    activityID: String,
  },
  data() {
    return {
      editDialog: false,
      editStaff: false,
      currView: "TopUpFeeSetting",
      sanqiu: false,
      nodata: 0,
      form: {
        resource: 1,
        radio: 1,
      },
      ChargeConfig: {
        // id: 1,
        actId: this.activityID,
        adminId: null,
        actChargeType: "staff_auto",
        brandBonusBean: 0,
        staffBonusBean: 0,
        brandPenalty: 0,
        userShareBonus: 0,
        staffSingleBonus: 0,
        liveRedPackage: 0,
        otherPromotionExpenses: 0,
        staffBonus: 0,
      },
      ActivityObj: {
        // id: 1,
        actId: this.activityID,
        adminId: null,
        actChargeType: "act",
        brandBonusBean: 0,
        staffBonusBean: 0,
        brandPenalty: 0,
        userShareBonus: 0,
        staffSingleBonus: 0,
        liveRedPackage: 0,
        otherPromotionExpenses: 0,
      },
      StaffBonus: 0,
      editActivity: {},
      KpiUserId: 0,
      CustomerBrandTotal: 0,
      ActivityBrandTotal: 0,
      CustomerStaffTotal: 0,
    };
  },
  methods: {
    changeradio(val) {
      console.log(val);
      var title = "";
      var message = "";
      if (val == "act") {
        title = "活动方自充";
        message = "导购员注册后无需充值即可进入KPI页面，确定切换吗";
        this.ChargeConfig.actChargeType = "staff_auto";
      } else {
        title = "客户自充";
        message = "导购员注册后需充值完成，方可进入KPI页面，确定切换吗";
        this.ChargeConfig.actChargeType = "act";
      }
      this.$confirm(message, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val == "act") {
            this.sanqiu = true;
            this.ChargeConfig.actChargeType = "act";
            this.submitChargeConfig();
          } else {
            this.sanqiu = false;
            this.ChargeConfig.actChargeType = "staff_auto";
            this.submitChargeConfig();
          }
        })
        .catch(() => {});
    },
    // 充值费用设置接口-获取数据（客户自充）
    async getChargeConfig() {
      try {
        const data = await FinanceAjax.getChargeConfig(this.activityID);
        if (data.data) {
          var total = 0;
          this.ChargeConfig = data.data;
          for (var key in this.ChargeConfig) {
            if (
              key == "brandBonusBean" ||
              key == "staffBonusBean" ||
              key == "brandPenalty" ||
              key == "userShareBonus" ||
              key == "staffSingleBonus" ||
              key == "liveRedPackage" ||
              key == "otherPromotionExpenses"
            ) {
              total = Number(total).add(this.ChargeConfig[key]);
            }
          }
          this.CustomerBrandTotal = total;
          this.CustomerStaffTotal = this.ChargeConfig.staffBonus;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 充值费用设置接口-获取数据（活动方自充）
    async getChargeFinanceChargeSingleSum() {
      try {
        const data = await FinanceAjax.getChargeFinanceChargeSingleSum(
          this.activityID
        );
        if (data.data) {
          var detail = data.data;
          var total = 0;
          this.ActivityObj.brandBonusBean = detail.brandBonusBean;
          this.ActivityObj.brandPenalty = detail.brandPenalty;
          this.ActivityObj.liveRedPackage = detail.liveRedPackage;
          this.ActivityObj.otherPromotionExpenses =
            detail.otherPromotionExpenses;
          this.ActivityObj.staffSingleBonus = detail.staffSingleBonus;
          this.ActivityObj.staffBonusBean = detail.staffBonusBean;
          this.ActivityObj.userShareBonus = detail.userShareBonus;

          this.StaffBonus = detail.staffBonus;
          total = Number(total)
            .add(this.ActivityObj.brandBonusBean)
            .add(this.ActivityObj.brandPenalty)
            .add(this.ActivityObj.liveRedPackage)
            .add(this.ActivityObj.otherPromotionExpenses)
            .add(this.ActivityObj.staffSingleBonus)
            .add(this.ActivityObj.staffBonusBean)
            .add(this.ActivityObj.userShareBonus);
          this.ActivityBrandTotal = total;
        }
        // const data2 = await FinanceAjax.getActacountQueryKpiUserSum(
        //   this.activityID
        // );
        // this.StaffBonus = data2.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 充值费用设置接口-修改数据
    async submitChargeConfig() {
      try {
        this.ChargeConfig.actId = this.activityID;
        let act = this.ChargeConfig;
        if (this.ChargeConfig.actChargeType == "act") {
          act = {
            actId: this.activityID,
            actChargeType: "act",
            id: this.ChargeConfig.id,
          };
        } else {
          // 对数据进行验证
          if (act.brandBonusBean > 99999999 || act.brandBonusBean < 0) {
            this.$message.warning("品牌奖励豆奖金池充值设置范围为0~99999999");
            return;
          }
          if (
            act.brandBonusBean == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.brandBonusBean) == false
          ) {
            this.$message.warning(
              "请输入正确品牌奖励豆奖金池充值金额，最多两位小数"
            );
            return;
          }
          if (act.staffBonusBean > 99999999 || act.staffBonusBean < 0) {
            this.$message.warning("导购员奖励豆奖金池充值设置范围为0~99999999");
            return;
          }
          if (
            act.staffBonusBean == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.staffBonusBean) == false
          ) {
            this.$message.warning(
              "请输入正确导购员奖励豆奖金池充值金额，最多两位小数"
            );
            return;
          }
          if (act.brandPenalty > 99999999 || act.brandPenalty < 0) {
            this.$message.warning("品牌惩罚承诺金充值设置范围为0~99999999");
            return;
          }
          if (
            act.brandPenalty == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.brandPenalty) == false
          ) {
            this.$message.warning(
              "请输入正确品牌惩罚承诺金充值金额，最多两位小数"
            );
            return;
          }
          if (act.userShareBonus > 99999999 || act.userShareBonus < 0) {
            this.$message.warning("客户分享奖励充值设置范围为0~99999999");
            return;
          }
          if (
            act.userShareBonus == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.userShareBonus) == false
          ) {
            this.$message.warning(
              "请输入正确客户分享奖励充值金额，最多两位小数"
            );
            return;
          }
          if (act.staffSingleBonus > 99999999 || act.staffSingleBonus < 0) {
            this.$message.warning("导购员单项奖励金充值设置范围为0~99999999");
            return;
          }
          if (
            act.staffSingleBonus == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.staffSingleBonus) == false
          ) {
            this.$message.warning(
              "请输入正确导购员单项奖励金充值金额，最多两位小数"
            );
            return;
          }
          if (act.liveRedPackage > 99999999 || act.liveRedPackage < 0) {
            this.$message.warning("直播间红包充值设置范围为0~99999999");
            return;
          }
          if (
            act.liveRedPackage == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.liveRedPackage) == false
          ) {
            this.$message.warning("请输入正确直播间红包充值金额，最多两位小数");
            return;
          }

          if (
            act.otherPromotionExpenses > 99999999 ||
            act.otherPromotionExpenses < 0
          ) {
            this.$message.warning("其他推广费用充值设置范围为0~99999999");
            return;
          }
          if (
            act.otherPromotionExpenses == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.otherPromotionExpenses) == false
          ) {
            this.$message.warning(
              "请输入正确其他推广费用充值金额，最多两位小数"
            );
            return;
          }
          if (act.staffBonus > 99999999 || act.staffBonus < 0) {
            this.$message.warning("导购员奖金池充值设置范围为0~99999999");
            return;
          }
          console.log(act.staffBonus);
          if (
            act.staffBonus == "" &&
            /^\d+(\.\d{1,2})?$/.test(act.staffBonus) == false
          ) {
            this.$message.warning(
              "请输入正确导购员奖金池充值金额，最多两位小数"
            );
            return;
          }
        }
        console.log(act);
        await FinanceAjax.saveChargeConfig(act);
        this.$message.success("操作成功");
        await this.getChargeConfig();
        await this.getChargeFinanceChargeSingleSum();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入活动方充值页面
    handleEdit(type, row) {
      if (type == 1) {
        this.editActivity = {
          actId: this.activityID,
          adminId: this.KpiUserId,
          kpiUserId: 0,
          chargeUserType: "brand",
          brandBonusBean: 0,
          staffBonusBean: 0,
          brandPenalty: 0,
          userShareBonus: 0,
          staffSingleBonus: 0,
          liveRedPackage: 0,
          otherPromotionExpenses: 0,
          // brandBonusBean: this.ActivityObj[0].amount,
          // staffBonusBean: this.ActivityObj[4].amount,
          // brandPenalty: this.ActivityObj[1].amount,
          // userShareBonus: this.ActivityObj[6].amount,
          // staffSingleBonus: this.ActivityObj[5].amount,
          // liveRedPackage: this.ActivityObj[2].amount,
          // otherPromotionExpenses: this.ActivityObj[3].amount,
        };
        this.editDialog = true;
      } else {
        this.editActivity = {
          actId: this.activityID,
          adminId: this.KpiUserId,
          kpiUserId: 0,
          chargeUserType: "staff",
          amount: 0,
          tradeNo: "string",
          type: "string",
          // staffBonus: this.StaffBonus
        };
        this.editStaff = true;
      }
    },
    // 活动方品牌充值
    async saveeditActivity() {
      try {
        let act = this.editActivity;
        // 7个数值校验，禁止输入0
        if (
          !this.editActivity.brandBonusBean &&
          !this.editActivity.brandPenalty &&
          !this.editActivity.liveRedPackage &&
          !this.editActivity.otherPromotionExpenses &&
          !this.editActivity.staffBonusBean &&
          !this.editActivity.userShareBonus &&
          !this.editActivity.staffSingleBonus
        ) {
          this.$message.warning("至少充值一种类型且需大于0");
          return;
        }
        console.log(act);
        await FinanceAjax.saveBrandChargeConfig(act);
        this.$message.success("操作成功");
        this.editDialog = false;
        await this.getChargeConfig();
        await this.getChargeFinanceChargeSingleSum();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 活动方导购员充值
    async saveeditStaff() {
      try {
        this.editActivity.actId = this.activityID;
        this.editActivity.chargeUserType = "staff";
        let act = this.editActivity;
        if (!this.editActivity.amount) {
          this.$message.warning("充值金额需大于0");
          return;
        }
        await FinanceAjax.saveKpiUserCharge(act);
        this.$message.success("操作成功");
        this.editStaff = false;
        await this.getChargeConfig();
        await this.getChargeFinanceChargeSingleSum();
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getChargeConfig();
    this.getChargeFinanceChargeSingleSum();
    var kpiuserId = getSession("admindetail");
    console.log(kpiuserId);
    this.KpiUserId = kpiuserId.id;
  },
};
</script>

<style lang="scss" scoped>
.TopUpForm-waapper {
  .form-item-box {
    background: rgba(153, 153, 153, 0.075);
    padding: 40px;
    color: black;
    .item-title {
      font-size: 22px;
      font-weight: 600;
    }
    .item-input-box {
      div {
        margin-right: 40px;
      }
    }
  }
  .option-btn {
    font-size: 13px;
    color: #0c7efe;
    display: inline-block;
    margin-left: 20px;
  }
}
</style>